if (typeof Livewire != "undefined") {
    Livewire.on('gotoTop', (id) => {
        var topPosition = 15;

        if(id && id.length) {
            id = id.pop();

            const element = document.getElementById('block-' + id);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                topPosition = elementPosition - (window.innerHeight/3);
            }
        }

        window.scrollTo({
            top: topPosition,
            left: 0,
            behaviour: 'smooth'
        });
    })
}
