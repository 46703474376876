if (typeof Livewire != "undefined") {
    Livewire.on('validate-inputs', () => {
        const btn = $('.js-btn-next')
        btn.find('.spinner-wrapper.d-none').removeClass('d-none');
        btn.find('.label').css({ opacity: 0 })
    });

    Livewire.on('next-item', () => {
        const btn = $('.js-btn-next')
        btn.find('.spinner-wrapper.d-none').removeClass('d-none');
        btn.find('.label').css({ opacity: 0 })
    });

    Livewire.on('previous-item', () => {
        const btn = $('.js-btn-prev')
        btn.find('.spinner-wrapper.d-none').removeClass('d-none');
        btn.find('.label').css({ opacity: 0 })
    });
 }
